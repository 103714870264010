import React, {  useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import HeaderImg from "./HeaderImg";
import FooterImg from "./FooterImg";
import WrapperCanvas from "./canvas/WrapperCanvas";

import { adobeAreas, adobeCanvas, adobePhraseCanvas } from "./utils/findAreas";
import { findWidthCanvasItem, findSize, findX, findY, findHeightCanvasItem } from "./utils/findLength";
import { API_ROOT_CALLS, getImageLoad } from "./API";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 5,
    paddingTop: 0
  },
  img: {
    margin: "5px auto 0px auto",
    display: "block",
    height: 74
  },
  imgRedactor: {
    width: "100%",
    minHeight: 100,
    backgroundColor: "#C8C8C8",
    objectFit: "contain",
    border: "1px solid rgb(1, 161, 199)"

  },
  bgImgRedactorWrapper: {
    backgroundImage: "linear-gradient(45deg, #f5f5f5 25%, transparent 25%, transparent 75%, #f5f5f5 75%, #f5f5f5), linear-gradient(45deg, #f5f5f5 25%, transparent 25%, transparent 75%, #f5f5f5 75%, #f5f5f5)",
    backgroundSize: "15px 15px",
    backgroundPosition: "0 0, 7px 7px",
    border: "1px solid rgb(1, 161, 199)"
  },
  bgImgRedactor: {
    height: "100%",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover"
    // backgroundRepeat: "no-repeat"
  }
}));


const ImgTypeWrapper = ({ el, vue, setBlock, context, disableHeader}) => {
  const cl = useStyles();
  const token = localStorage.getItem("accessToken");
  const ref = useRef(null);
  const [hotspots, setHotspots] = useState({
    percents: 100,
    hideHotspots: false,
    width: "100%",
  });
  const [values, setValues] = useState({
    item: [],
    selectedItem: null,
    width: "100%",
    height: 400,
    phraseCanvas: {}
  });
  const widthBackground = findSize(values.width, hotspots.percents);
  const heightBackground = findSize(values.height, hotspots.percents);

  const getImgPhrase = async () => {
    const res = await getImageLoad(el.elementInfo.versionId);
    if (res && res.elements) {
      const getPhraseCanvas = adobePhraseCanvas(res.elements);
      setValues(pre => ({
        ...pre,
        phraseCanvas: res,
        item: [...pre.item, ...getPhraseCanvas],
        width: res.width,
        height: res.height
      }));
    }
  };


  useEffect(() => {
    if (el.areas?.length > 0) {
      const res = adobeAreas(el.areas);
      setValues(pre => ({ ...pre, item: [...pre.item, ...res] }));
    }
  }, []);

  useEffect(() => {
    if (!vue && values.item.length > 0) {
      const findHotspot = values.item.filter(el => !el.text);
      const phraseCanvas = values.item.filter(el => el.text);
      if(setBlock){
        setBlock(pre => ({
          ...pre,
          blockItem: {
            ...pre.blockItem,
            areas: adobeCanvas(findHotspot),
            phraseCanvas: {...values.phraseCanvas, elements: adobePhraseCanvas(phraseCanvas)},
          }
        }));
      }
    }
  }, [vue, values.item]);

  useEffect(() => {
    if (!vue && values.item.length > 0) {
      const res = values.item.map(el => {
        return {
          ...el,
          width: findWidthCanvasItem(el, hotspots),
          height: findHeightCanvasItem(el, hotspots),
          x: findX(el, hotspots),
          y: findY(el, hotspots)
        };
      });
      setValues(pre => ({ ...pre, item: res }));
    }
  }, [hotspots.percents, vue]);


  useEffect(() => {
    if (!vue) {
      getImgPhrase();
    }
  }, [vue]);

  useEffect(() => {
  //   const handleResize = (entries) => {
  //     const elementWidth = entries[0].contentRect.width;
  //     setHotspots(prev => ({ ...prev, width: elementWidth }));
  //   };
  //
  //  const resizeObserver = new ResizeObserver(handleResize);
  //   if (ref.current) {
  //     resizeObserver.observe(ref.current);
  //   }
  //
  //   return () => {
  //     if (ref.current) {
  //       resizeObserver.unobserve(ref.current);
  //     }
  //   };
  }, [ref, vue]);



  return (
    <div className={cl.root} ref={ref}>
      {vue && <img className={cl.img}
                   src={API_ROOT_CALLS + `/image/getImagePreviewById/${el.elementInfo.versionId}?jwtToken=${token}`}
                   alt="img" />}
      {!vue && <>
      {!disableHeader && <HeaderImg
          el={el}
          hotspots={hotspots}
          setHotspots={setHotspots}
          values={values}
          setCanvas={setValues}
          context={context}
        />}
        <div style={{
          overflow: "auto",
          width: hotspots.width
        }}>
          <div className={cl.bgImgRedactorWrapper} style={{
            minHeight: heightBackground > 0 ? heightBackground : 400,
            minWidth: widthBackground > 0 ? widthBackground : "100%",
            width: widthBackground,
            height: findSize(values.height, hotspots.percents),
            backgroundColor: "#C8C8C8"
          }}>
            <WrapperCanvas values={values} setValues={setValues} hotspots={hotspots} context={context} el={el}>
              <div className={cl.bgImgRedactor} style={{
                backgroundImage: `url(${API_ROOT_CALLS}/image/getImageById/${el.elementInfo.versionId}?jwtToken=${token})`
              }}></div>
            </WrapperCanvas>
          </div>
        </div>
        <FooterImg hotspots={hotspots} setHotspots={setHotspots} />
      </>}
    </div>
  );
};

export default ImgTypeWrapper;