import React, { useContext, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from "@mui/material";
import TextFieldItem from "@zert-packages/components/TextFieldItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SelectAliasDialog from "./SelectAliasDialog";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import { getLoadAliasesByPhraseApi } from "../../../API";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 414,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700,
    textTransform: "capitalize"
  },
  wrapperTextFieldItem: {
    display: "flex",
    gap: 10
  },
  underTitle: {
    fontWeight: 700
  },
  img: {
    cursor: "pointer",
    color: "#4db1d3"
  },
  wrapperButton: {
    display: "flex",
    gap: 10,
    marginTop: 30,
    justifyContent: "end"
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#4db1d3",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));


const DialogAddAlias = ({ addAlias, values, propsText, versionId }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [alias, setAlias] = useState({
    values: "",
    aliasList: [],
    selectedAlias: null
  });


  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (alias.values.length > 0) {
      const val =  alias.values + " "
      setOpen(false);
      addAlias(val);
    }
  };

  const handleAlias = (val, name) => {
    setAlias(pre => ({ ...pre, [name]: val }));
  };

  const addNewAlias = (val) => {
    setAlias(pre => ({
      ...pre,
      selectedAlias: val,
      values: val.value
    }));
  };

  const addLabel = () => {
    renderInExceptionRoot(SelectAliasDialog, { alias, addNewAlias });
  };

  const getAlias = async () => {
    const id = versionId ||  values?.treeItemSelectedContext?.elementInfo?.versionId;
    const res = await getLoadAliasesByPhraseApi(id);
    if (res && id) {
      setAlias(pre => ({ ...pre, aliasList: res }));
    }
  };

  useEffect(() => {
    if (propsText) {
      setAlias(pre => ({ ...pre, values: propsText.decoratedText }));
    }
  }, [propsText]);

  useEffect(() => {
    getAlias();
  }, []);


//aliasDecoratorWord
  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>Insert alias</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div className={cl.wrapperTextFieldItem}>
      <span className={cl.underTitle}> Name:</span>
      <TextFieldItem values={alias.values} setValues={handleAlias} name={"values"} autoFocus={true} />
      {alias.aliasList.length > 0 && <MoreHorizIcon className={cl.img} onClick={addLabel} />}
    </div>
    <div className={cl.wrapperButton}>
      <Button className={cl.button} onClick={handleSave}>
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>);
};

export default DialogAddAlias;