import React, {createContext, memo, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {FormattedMessage} from 'react-intl';
import ListCore from '../ListCore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListItem from "./components/ListItem";
import CreateList from "./components/CreateList";




const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-end'
    },

    CreateNewLabel: {
        color: '#1BA1C5',
        fontSize: '1.5rem',
        [theme.breakpoints.down('tablet')]: {
            fontSize: '1.25rem'
        },
        // borderLeft: '2px solid #1BA1C5',
        padding: '10px 20px',
        paddingLeft: 0,
        fontWeight: 'normal',
        // borderBottom: 'solid 1px rgba(27, 161, 197, .05)',
        // marginBottom: 5,
        fontFamily: `'Open Sans', sans-serif`
    },
    icon: (pros) => ({
        paddingTop: 15,
        paddingBottom: 15,
        display: 'block',
        transform: pros.open ? 'rotate(180deg)' : '',
    }),
    wrapperList: {
        marginBottom: 10,
    },
}));

function CreateNewList({list, onClick}) {
    const cl = useStyles();
    const [create, setCreate] = useState({
        open: false,
        createType: null,
    })



    return (
        <div className={cl.root} data-intro-id="SideBar.CreateNew">
                <CreateNewLabel create={create} setCreate={setCreate}/>
                {create.open && <div className={cl.wrapperList}>
                    <CreateList setCreate={setCreate}/>
                </div>}
                <ListCore list={list} onClick={onClick}/>

        </div>
    );
}

export default CreateNewList;

const CreateNewLabel = memo(({create, setCreate}) => {
        const cl = useStyles({open: create.open});
        const handleOpen = () => {
            setCreate(pre => ({...pre, open: !pre.open}))
        }

        return (<div onClick={handleOpen}>
            <ListItem
                icon={<span className={cl.icon}>
                        <KeyboardArrowDownIcon/>
                     </span>}
                component={<div className={cl.CreateNewLabel} style={{cursor: 'pointer'}}>
                    <FormattedMessage id="Packages.Components.SideBar.CreateNewLabel" defaultMessage="Create new"/>
                </div>}
            />
        </div>)
    }
);
