import React, { createContext, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import handleValueState from '@zert-packages/utils/handleValueState';
import useVersionManager from '@zert-packages/utils/useVersionManager';
import { setHeaderScroll } from '@zert-packages/actions/commonReducer';
import { useParams } from 'react-router-dom';
import SidePanel from '../SidePanel';
import useStyles from './styles';
import AbstractWrapper from '../components/AbstractWrapper';
import BorderTypeColor from '../helpers/BorderTypeColor';
import { dataContext } from '../data/dataContext';

export const PanelContext = createContext(null);

let prevVal = '';
const render = true;

function InfoElementWrapper({ children }) {
  const cl = useStyles();
  const { versionId } = useParams();
  const [expander, setExpander] = useState({
    left: false,
    right: false
  });
  const [values, setValues] = useState(dataContext);
  const [versionManager, versionInfo] = useVersionManager();
  const selectedParent = values.treeItem && values.treeItem.parentTreeItem;
  const type = versionInfo?.info?.mimeType.split('/').pop();
  const [scroll, setScroll] = useState(0);
  const dispatch = useDispatch();
  const { actionHeader, myLanguage, scrollHeader } = useSelector((state) => state);

  const saveAllItem = () => {
    if (values.treeItem) {
      return handleValueState(null, null, setValues, {
        closeChild: false,
        dropBlockTable: false,
        closeAllBlockItems: true,
        parentBlock: versionInfo,
        investmentLevel: 0,
        addPublishingFilters: null
      });
    }
    setValues((pre) => ({
      ...pre,
      parentBlock: versionInfo,
      closeAllBlockItems: true,
      addPublishingFilters: null
    }));
  };

  const selectedInfoElement = (e) => {
    if (e.target.getAttribute('name') === 'allowСlick' && !values.blockClickCreateItemsBlock) {
      saveAllItem();
    }
  };

  const handleExpander = (block) => {
    setExpander({ ...expander, [block]: !expander[block] });
  };

  const findWidthBlock = () => {
    if (expander.left && expander.right) {
      return '1fr';
    }
    if (expander.left) {
      return '4.2fr 1.2fr';
    }
    if (expander.right) {
      return '1.2fr 4.2fr';
    }
    return '1.2fr 4fr 1.2fr';
  };

  useEffect(() => {
    if (versionInfo?.info?.name) {
      handleValueState(null, null, setValues, {
        valuesName: versionInfo.info.name,
        parentBlock: versionInfo
      });
    }
  }, [versionInfo]);

  const refContent = useCallback(
    (node) => {
      if (node && values.scrollTop) {
        setValues((pre) => ({ ...pre, scrollTop: false }));
        node.scrollTop = 0;
        // node.style.transform = 'translateY(0px)';
      }
    },
    [values.scrollTop]
  );

  const handleScroll = (e) => {
    const currentScrollTop = e.target.scrollTop;
    const scrollDirection = currentScrollTop > scroll ? 'down' : 'up';
    setScroll(currentScrollTop);
    if (scrollDirection !== prevVal) {
      prevVal = scrollDirection;
      dispatch(setHeaderScroll(scrollDirection === 'down'));
    }
  };

  const selectedHandleParent = () => {
    handleValueState(null, null, setValues, {
      closeChild: false,
      dropBlockTable: false,
      closeAllBlockItems: true,
      parentBlock: versionInfo,
      investmentLevel: 0,
      treeItem: values.parentItemChild
    });
  };

  useEffect(() => {
    if (actionHeader && myLanguage) {
      saveAllItem();
      // saveBlock(snackbar, dispatch, values, myLanguage.value, saveAllItem);
    }
  }, [actionHeader]);

  // console.log('values', values.changeBlock, values); lockSidePanel

  return (
    <PanelContext.Provider value={{ values, setValues }}>
      <div
        className={`${cl.root}`}
        style={{
          height: 'calc(100% - 56px)',
          marginTop: 56,
          gridTemplateColumns: findWidthBlock()
        }}
      >
        <SidePanel
          id="EditorLeftSidePanel"
          panels={['structure', 'variables', 'comments']}
          onClick={(e) => {
            selectedInfoElement(e);
          }}
          title={
            <FormattedMessage id="structure.variables.comments" defaultMessage="Structure, Variables & Comments" />
          }
          direction="left"
        />

        <div className={cl.wrapperEditor}>
          <div
            className={cl.wrapperMiddleBlock}
            name="allowСlick"
            onClick={(e) => {
              selectedInfoElement(e);
            }}
            style={{
              borderLeft: selectedParent ? '1px solid #01A1C7' : '',
              borderRight: selectedParent ? '1px solid #01A1C7' : ''
            }}
          >
            <BorderTypeColor name="allowСlick" el={versionInfo?.info} />
            <div className={cl.wrapperContent}>
              <div>
                <AbstractWrapper
                  id={versionInfo?.info.currentVersionId}
                  info={versionInfo?.info}
                  setValues={setValues}
                  closeOnclick
                  treeItem={values.treeItem}
                  el={versionInfo}
                />
              </div>
              <div className={cl.wrapperScroll} name="allowСlick">
                {children}
              </div>
            </div>
          </div>
        </div>
        <SidePanel
          id="EditorRightSidePanel"
          panels={['properties', 'filters', 'metadata']}
          multiSelect
          onClick={(e) => {
            selectedInfoElement(e);
          }}
          title={<FormattedMessage id="properties.filters.metadata" defaultMessage="Properties, Filters & Metadata" />}
          direction="right"
        />
      </div>
    </PanelContext.Provider>
  );
}

export default InfoElementWrapper;
