import React, {useCallback, useContext, useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import BlockItems, {BlockItemsContext} from "./BlockItems";
import blockItemsData from "./data/blockItemsData";
import PhraseEditor from "../PhrasePlugin/components/PhraseEditor";
import AbstractWrapper from "@zert-packages/components/InfoElement/components/AbstractWrapper";
import TextFieldItem from "@zert-packages/components/TextFieldItem";
import TableEditorWrapper from "../PhrasePlugin/components/table/TableEditorWrapper";
import IndexText from "./components/IndexText";
import {PanelContext} from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {
    createBlockItem,
    createIndex,
    createPhrase,
    createTableItem,
    preSaveItems, preSavePhrase
} from "./utils/createItem";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import SkeletonLoader from "@zert-packages/components/SkeletonLoader";
import {findIdParent} from "./utils/main";
import CustomTable from "../TablePlugin/resizedTable/CustomTable";
import {createInProgressSnack} from "@zert-packages/components/common/snackBar";
import {FormattedMessage} from "react-intl";
import {saveTableBlock} from "@zert-packages/components/InfoElement/helpers/ToolBarAction";

const useStyles = makeStyles((theme) => ({
    wrapperBlockItems: {
        border: "1px solid #01A1C7",
        minHeight: 42,
        paddingRight: 5,
        marginBottom: 10,
        cursor: "default"
    },
    container: {
        padding: 5
    },
    wrapperAddToolbar: {
        display: "grid",
        gridTemplateColumns: "25px 1fr",
        marginTop: 8
    },
    wrapperTextField: {
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5
    },
    addImgButton: (props) => ({
        height: 16,
        width: 16,
        paddingTop: 3,
        backgroundColor: "#59C1DB",
        color: "#ffffff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 3,
        fontSize: 15,
        fontWeight: 900,
        paddingBottom: 4,
        cursor: props.blockClick ? "not-allowed" : "pointer"
    })
}));


const CreateBlockItems = ({createType, props, versionIdParent}) => {
    const {
        context,
        parentItemCreate
    } = props;
    const [createBlock, setCreateBlock] = useState({
        loadingInit: false,
        table: null,
    });
    const {block, setBlock} = useContext(BlockItemsContext);
    const {values, setValues} = useContext(context ? context : PanelContext);
    const blockClick = values.blockClickCreateItemsBlock;
    const error = block.nameBlock.length === 0;
    const cl = useStyles({blockClick});
    // const selectedItems = blockItemsData[createType];
    // const type = selectedItems.elementInfo.mimeType.split("/").pop();
    const selectedItems = values.treeItem
    const type = 'zert-' + createType
    const {myLanguage} = useSelector((state) => state);
    const {versionId} = useParams();
    const id = values?.parentItem?.elementInfo?.versionId;
    // const parentVersionId = (id && values?.investmentLevel > 1) ? id : versionId;
    const parentVersionId = findIdParent(id, values, versionId, versionIdParent)
    const snackbar = useSnackbar();
    const [name, setName] = useState("");
    const [actionTable, setActionTable] = useState("");


    const addSaveBlock = (e) => {
        if (blockClick) {
            return;
        }
        setBlock(pre => ({...pre, saveCreateElement: true}));
        setValues(pre => ({...pre, expandedTreeItem: pre.treeItem}));
    };

    const handleName = (val) => {
        setName(val);
        fetchUsers(val);
    };

    const fetchUsers = useCallback(_.debounce((val) => {
        preSaveItems(val, setBlock, setValues, block);
    }, 350), []);

    const createInitBlock = async () => {

        const mylocale = myLanguage.value

        if (type === "zert-phrase") {
            await createPhrase(snackbar, mylocale, parentVersionId, setValues);
        }
        if (type === "zert-block") {
            setCreateBlock(pre => ({...pre, loadingInit: true}));
            handleName("");
            await createBlockItem(snackbar, mylocale, parentVersionId, setValues);
            setCreateBlock(pre => ({...pre, loadingInit: false}));
        }
        if (type === "zert-table") {
            setCreateBlock(pre => ({...pre, loadingInit: true}));
            await createTableItem(snackbar, mylocale, parentVersionId, setValues);
            setCreateBlock(pre => ({...pre, loadingInit: false}));
        }
        if (type === "zert-index") {
            setCreateBlock(pre => ({...pre, loadingInit: true}));
            await createIndex(snackbar, mylocale, parentVersionId, setValues);
            setCreateBlock(pre => ({...pre, loadingInit: false}));
        }
    };

    const saveTable = async () => {
        if(createBlock.table.name.length === 0){
            return
        }
        const generatingSnackbar = createInProgressSnack(
            snackbar,
            <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..."/>
        );


        // setActionTable('save')
        await saveTableBlock(parentItemCreate, values,  createBlock.table, setValues, block, setBlock, myLanguage,)
        snackbar.closeSnackbar(generatingSnackbar);
    }

    const handleTable = (table) => {
        setCreateBlock(pre => ({...pre, table}))
    }

    useEffect(() => {
        createInitBlock();
    }, [type]);

    useEffect(() => {
        preSavePhrase(type, block, setValues);
    }, [block.phraseEditor.phrase]);




    return (<div className={cl.wrapperBlockItems}>
        <div className={cl.container}>
            {selectedItems && <AbstractWrapper
                child={true}
                id={selectedItems.elementInfo.versionId}
                info={selectedItems.elementInfo}
                expendBlock={true}
                createBlock={true}
                el={values.action.preSaveElement ? values.action.preSaveElement : selectedItems}
                // el={values.treeItem}
                showSideBar={true}
                block={block}
                parentItemCreate={{...parentItemCreate, type: "create"}}
                setBlock={setBlock}
                saveOnlyItem={type === "zert-table" ? saveTable : false}
            />}
            {type === "zert-phrase" && <div style={{marginTop: 5}}>
                <PhraseEditor el={selectedItems} vue={true} newFraze={true}/>
            </div>}

            {createBlock.loadingInit
                ? <SkeletonLoader height={38}/>
                : (type === "zert-block" || type === "zert-index") &&
                <div className={cl.wrapperTextField}>
                    <div style={{"WebkitBoxShadow": error ? "0 0 10px red" : ""}}>
                        <TextFieldItem
                            // values={block.nameBlock}
                            values={name}
                            name={"nameBlock"}
                            setValues={handleName}
                            error={error}
                            autoFocus={true}
                        />
                    </div>
                </div>}
            {type === "zert-table" && selectedItems && <>
                <CustomTable
                    table={selectedItems}
                    setTable={handleTable}
                    versionId={selectedItems.elementInfo.currentVersionId}
                    actionTypeProps={actionTable}
                />
            </>}
            {/*selectedItems*/}
            {/*{type === "zert-table" && values.treeItem && */}
            {/*    <TableEditorWrapper*/}
            {/*  init={values.treeItem}*/}
            {/*  vue={false} />*/}
            {/*}*/}
            {type === "zert-index" && <IndexText/>}
            {type === "zert-block" && <>
                <div className={cl.addImgButton} onClick={addSaveBlock}>
                    <AddIcon/>
                </div>
            </>}


        </div>
    </div>);
};

export default CreateBlockItems;