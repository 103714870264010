import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { FilterList } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import SmartFilter from '../shared/SmartFilter';
import AdaptableToolbar from '../ToolBar/AdaptableToolbar';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    display: 'grid',
    gap: '0',
    gridTemplateColumns: '1fr fit-content(10%) fit-content(20%) fit-content(20%)',
    gridTemplateRows: 'auto 39px auto',
    gridTemplateAreas:
      "'breadcrumbs tableactions tilesfilter tablesearch'" +
      "'toolbar toolbar toolbar toolbar'" +
      "'message message message message'"
    // minHeight: 'unset'
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.main, 0.9)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%',
    gridArea: 'breadcrumbs',
    paddingLeft: '5px'
  },
  tableactions: {
    gridArea: 'tableactions',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '5px'
  },
  tablesearch: {
    gridArea: 'tablesearch',
    width: '250px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    height: '100%'
  },
  tilesfilter: {
    gridArea: 'tilesfilter',
    display: 'flex'
  },
  toolbar: {
    gridArea: 'toolbar',
    padding: '0 5px',
    '& *': {
      margin: '2px'
    }
  },
  message: {
    gridArea: 'message'
  }
}));

function EnhancedTableToolbar(props) {
  const classes = useToolbarStyles();

  const {
    actions,
    selected,
    otherActions,
    bradCrumbComponent,
    showElementActions,
    toolbarRef,
    reloadElement,
    setActiveElement,
    hideToolBar,
    showCut,
    showFileUpload,
    ...other
  } = props;

  const { elementActions, performActionHandler, toolbarActions } = actions({ selected, ...other });
  //   const [ filterValue,  updateFilterValue ] = useState("");
  const actionMenu = showElementActions ? toolbarActions : elementActions;

  useEffect(() => {
    // console.log(setActiveElement, 'setActiveElement')
    if (setActiveElement) {
      setActiveElement(selected);
    }
  }, [selected]);
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: selected.length > 0
      })}
      ref={toolbarRef}
    >
      {selected.length > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          <FormattedMessage
            id="EnhancedTable.SelectedRows"
            defaultMessage="{numSelected} selected"
            values={{ numSelected: selected.length }}
          />
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {bradCrumbComponent}
        </Typography>
      )}

      {!hideToolBar && (
        <AdaptableToolbar
          actions={actions}
          selected={selected}
          snackbarComponent={bradCrumbComponent}
          showElementActions={showElementActions}
          reloadElement={reloadElement}
          setActiveElement={setActiveElement}
          showCut={showCut}
          showFileUpload={showFileUpload}
        />
      )}

      <div className={classes.tableactions}>
        {otherActions &&
          otherActions.map((action, index) => (
            <Tooltip title={action.name} key={action.name + index}>
              <IconButton onClick={(e) => action.performAction()} size="large">
                {action.icon}
              </IconButton>
            </Tooltip>
          ))}

        {!props.isShowingFilter && (
          <Tooltip title={<FormattedMessage id="EnhancedTable.FilterList" defaultMessage="Show filter" />}>
            <IconButton aria-label="filter list" onClick={() => props.setIsShowingFilter(true)} size="large">
              <FilterList />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {props.isShowingFilter && props.sorting && <div className={classes.tilesfilter}>{props.sorting}</div>}
      {props.isShowingFilter && (
        <SmartFilter
          value={props.filterValue}
          clear={() => props.setIsShowingFilter(false)}
          onChange={props.updateFilterValue}
          pos={classes.tablesearch}
          messagePos={classes.message}
        />
      )}
    </Toolbar>
  );
}

export default EnhancedTableToolbar;
