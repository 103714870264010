import React from 'react'
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import {
    checkCellLength,
    copyElement, findKeepRow, findSelectedColorCell,
    findSelectedColorRow, handleColorCell, handleColorRow, handleKeepWidth,
    handleReuniteCell,
    pastElement,
    remove,
} from "../helpers/main";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import AlertTextDialog from "@zert-packages/components/InfoElement/components/dialog/AlertTextDialog";
import DropMenu from "@zert-packages/components/DropMenu";
import PaletteIcon from "@mui/icons-material/Palette";
import DynamicRowBurgerMenu from "./DynamicRowBurgerMenu";

const useStyles = makeStyles((theme) => ({

    button: {
        '&:hover': {
            background: '#188ec7 !important',
            color: '#ffffff !important',
        },
        '&:focus': {
            background: 'none',
            outline: 'none',
            color: '#188ec7',
        },
    },
    index: {
        zIndex: '100',
        position: 'relative'
    },
    wrapperColor: {
        display: 'flex',
        gap: 5,
    },
    selectedColor: {
        width: 21,
        height: 21,
        border: '1px solid #188ec7',
        borderRadius: 5,
    }
}))

const ToolBarEditorTable = ({selected, setValues, values, uniteCells2, tableData, setTableData, setSelected}) => {
    const cl = useStyles();
    const snackbar = useSnackbar();
    const {myLanguage} = useSelector((state) => state);

    const deleteCell = () => {
        remove(tableData, values, setValues, selected, setTableData, setSelected)
    }

    const handleCut = () => {
        copyElement(setValues, selected)
        deleteCell()
    }


    const removeCell = () => {
        renderInExceptionRoot(AlertTextDialog, {
            setConfirm: deleteCell,
            title: "Remove ?",
            text: 'Are you sure that you want to remove this element?'
        });
    }
    const cutCell = () => {
        renderInExceptionRoot(AlertTextDialog, {
            setConfirm: handleCut,
            title: "Cut ?",
            text: 'Are you sure you want to cut this element?'
        });
    }

    const handleKeep = (val) => {
        if (selected.length === 0) {
            return
        }
        handleKeepWidth(val, selected, values, setValues)
    }

    const items = [
        {
            component: <div>
                <div>Rows:{tableData?.length}</div>
                <div>Selected:{selected?.length}</div>
            </div>
        },
        // {
        //     component: <Button className={cl.button}
        //                        onClick={() => saveTableElement(snackbar, values, myLanguage, tableData)}
        //                        variant={"outlined"}>
        //         save
        //     </Button>
        // },
        {
            component: <Button variant={"outlined"} className={cl.button} onClick={removeCell}>
                Remove
            </Button>
        },
        {
            component: <Button variant={"outlined"} className={cl.button} onClick={cutCell}>
                Cut
            </Button>
        },
        {
            component: <Button className={cl.button} onClick={() => copyElement(setValues, selected)}
                               variant={"outlined"}>
                copy
            </Button>
        },
        {
            notShow: !(values.copyCell?.filter(el => el.val).length > 0),
            component: <div>
                {values.copyCell?.filter(el => el.val).length > 0 && <Button
                    className={cl.button}
                    onClick={() => pastElement(values.copyCell, selected, tableData, setTableData, setSelected, setValues)}
                    variant={"outlined"}>
                    past
                </Button>}</div>
        },
        {
            notShow: selected?.length <= 1,
            component: <div>{selected?.length > 1 &&
                <Button className={cl.button} onClick={() => uniteCells2(tableData)} variant={"outlined"}>
                    marge
                </Button>
            }</div>
        },
        {
            notShow: !(selected?.length === 1 && checkCellLength(selected[0])),
            component: <>  {selected?.length === 1 && checkCellLength(selected[0]) &&
                <Button className={cl.button}
                        onClick={() => handleReuniteCell(tableData, selected, setSelected, setTableData, setValues)}
                        variant={"outlined"}>
                    split
                </Button>
            }</>
        },
        {
            component: <div>
                <div style={{marginBottom: 5}}>Keep with next</div>
                <DropMenu
                    setDropItem={handleKeep}
                    selected={findKeepRow(values, selected)}
                    dropItem={['None', 'Auto', 'Always']}
                />
            </div>
        },
        {
            component: <div className={cl.index}
                            onClick={() => handleColorRow(tableData, selected, values, setValues, setTableData,)}>
                <div style={{marginBottom: 7}}> Row color</div>
                <div className={cl.wrapperColor}>
                    <div className={cl.selectedColor}
                         style={{background: findSelectedColorRow(selected, tableData)}}></div>
                    <div style={{cursor: 'pointer', color: 'rgb(24, 142, 199)',}}>
                        <PaletteIcon/>
                    </div>
                </div>
            </div>
        },
        {
            component: <div className={cl.index}
                            onClick={() => handleColorCell(tableData, selected, values, setValues, setTableData, setSelected,)}>
                <div style={{marginBottom: 7}}> Cell color</div>
                <div className={cl.wrapperColor}>
                    <div className={cl.selectedColor}
                         style={{background: findSelectedColorCell(selected)}}></div>
                    <div style={{cursor: 'pointer', color: 'rgb(24, 142, 199)',}}>
                        <PaletteIcon/>
                    </div>
                </div>
            </div>
        },

    ]

    return (<div style={{paddingLeft: 19, paddingRight: 23}}>
        <DynamicRowBurgerMenu items={items} />
    </div>)
}

export default ToolBarEditorTable