import React, { useEffect, useState } from "react";
import PhraseEditor from "./components/PhraseEditor";
import { loadPhrase } from "./API";
import SkeletonLoader from "./components/SkeletonLoader";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import EditNoteIcon from '@mui/icons-material/EditNote';


const useStyles = makeStyles((theme) => ({
  wrapperImg: {
    display: "flex",
    alignItems: "center"
  },
  imgIcon: {
    position: "relative",
    top: 1,
    marginRight: 2
  }
}));

const PhraseBlockItemView = ({ block, vue, context, newFraze }) => {
  const cl = useStyles();
  const el = block.blockItem
  const [values, setValues] = useState({
    loader: false,
    styleMap: null,
    phrase: null
  });


  const loadPhraseItem = async () => {
    const phrase = await loadPhrase(el.elementInfo.versionId);
    if (phrase) {
      // const findImg = findStyleImg(el, phrase.elements);
      // setValues(pre => ({ ...pre, phrase: { ...phrase, elements: findImg } }));

      setValues(pre => ({ ...pre, phrase }));
    }
  };

  useEffect(() => {
    // if (el) {
    //   loadPhraseItem();
    // }
  }, [el]);


  // if (!values.phrase || !el) {
  //   return <SkeletonLoader height={"19"} />;
  // }
  const colorMain = '#01A1C7'





  return (
    <>
      {<div style={{display: vue ? "block" : "none"}}><PhraseEditor
        phrase={values.phrase}
        el={el}
        vue={vue}
        context={context}
        newFraze={newFraze}
      /></div>}
      {!vue &&  <div className={cl.wrapperImg} >
          {el?.format?.style === "NOTE" && <span className={cl.imgIcon} style={{color: colorMain}}>
            <EditNoteIcon />
          </span>}
          {el?.format?.style === "TIP" && <span className={cl.imgIcon} style={{color: `Yellow`}}>
            <TipsAndUpdatesIcon />
          </span>}
          {el?.format?.style === "INFO" && <span className={cl.imgIcon} style={{color: colorMain}}>
            <InfoIcon />
          </span>}
          {el?.format?.style === "WARNING" && <span className={cl.imgIcon}>
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="16" height="16" fill="red"
                 className="bi bi-exclamation-triangle-fill"
                 viewBox="0 0 16 16">
            <path
              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
          </svg></span>}
          {/*{values.phrase.elements.blocks.map(el => <span key={el.key}>{el.text}</span>)}*/}
         <span> {el?.elementInfo?.name}</span>
        </div>
      }
    </>
  );
};

export default PhraseBlockItemView;